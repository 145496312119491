import React,{useState,useEffect} from "react";
import {listGnbs, tddConfigs,UeColors,systemConfig} from "../constants/MapConstants";
import { Popup,Polyline,Polygon } from 'react-leaflet';
import { inverseHaversine } from "../common/helpers";

const BSs = ({onSetTddConfigs,showGnbs,onSelectedPci}) =>{
    const [selectedBs,setSelectedBs] = useState(false);
    const [show,setShow] = useState(false);
    const [highligtedSector,setHighlightedSector] = useState(0)
    const sectorEdgesAngels = [0,120,240];

    const onClickEvent = () =>{
        setSelectedBs(!selectedBs);
        onSetTddConfigs({"ttd":tddConfigs,"sys":systemConfig});
        }
    
    useEffect(() =>{
        if (showGnbs){
            setShow(true);
            }
        },[showGnbs]);



    const onClickSector = (pci) =>{
        setHighlightedSector(pci);
        onSelectedPci(pci);
    }

    const sectorDraw = (gnbInfo,sectorId) =>{
        
    return <Polygon  key={gnbInfo.pci[sectorId]}  pathOptions={{ opacity: 1,weight: 5, fillColor: highligtedSector === gnbInfo.pci[sectorId] ? "#ea0a8e" :"#ea0a8e"   ,
        color:highligtedSector === gnbInfo.pci[sectorId] ? "#ea0a8e" :UeColors[1]   ,fillOpacity:0.5 }} positions={inverseHaversine(gnbInfo.position,50,gnbInfo.azimuthAntenna[sectorId]-30).concat(inverseHaversine(gnbInfo.position,50,gnbInfo.azimuthAntenna[sectorId]+30).concat([inverseHaversine(gnbInfo.position,50,gnbInfo.azimuthAntenna[sectorId]-30)[1]]))}   eventHandlers={{click: () => {onClickSector(gnbInfo.pci[sectorId])},}}>

         <Popup key={gnbInfo.pci[sectorId]}>
         <span onClick={onClickEvent}>
             <ul>
             <li>Cell Id: {gnbInfo.id}</li>            
             <li>pci: {gnbInfo.pci[sectorId]}</li>            
             <li>System BW [MHz] :90 MHz (245 prb)</li> 
           </ul>
           </span>
         </Popup>
    </Polygon>
    
    }
    return <>

        { show && listGnbs.map((gnb) =>
        <>
        { [0,1,2].map( value => sectorDraw(gnb,value))}
        {sectorEdgesAngels.map((val,idx) => {
            return <Polyline key={gnb.pci[idx]} pathOptions={{ fillColor: UeColors[1],color:UeColors[1],fillOpacity:0.5 }} positions={inverseHaversine(gnb.position,100,val)} />
        }
        )}
        </>
        )}

        </>
}
export default BSs;

