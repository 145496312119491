import React,{useState} from "react";
import {Form,Col,Button,DropdownButton,Dropdown,ButtonGroup} from "react-bootstrap";
import {P,PrbBw,numerologies,PDSCH_TABLE} from "../common/helpers";


function SystemInputForm ({onSetSystemConfigs}){
    const [numerologie, setNumerologie] = useState(0);
    const [periodList,setPeriodList] = useState(P);
    const [bw,setBw] = useState(0);
    const [nprbs, setNprbs] = useState(273);
    const [pdschTable, setPdschTable] = useState(PDSCH_TABLE["NR_PDSCH_TABLE_2"]);
    const [mcs, setMcs] = useState(27);
    const [nLayersDl, setNLayersDl] = useState(1);
    const [nLayersUl, setNLayersUl] = useState(1);

    const setNumerologieAndPeriodN0 = () =>{
      setNumerologie(0);
      setBw((PrbBw[15]*nprbs)/1e3);    
      setPeriodList(P.filter(item => item !== 0.625 &&  item !== 1.25 && item !== 2.5));
      
  }
  const setNumerologieAndPeriodN1 = () =>{
      setNumerologie(1);
      setBw((PrbBw[30]*nprbs)/1e3);    
    
      setPeriodList( P.filter(item => item !== 0.625 && item !== 1.25 ));
      
  }
  const setNumerologieAndPeriodN2 = () =>{
      setNumerologie(2);
      setBw((PrbBw[60]*nprbs)/1e3);    
    
      setPeriodList( P.filter(item => item !== 0.625 ));

      
  }
  const setNumerologieAndPeriodN3 = () =>{
      setNumerologie(3);
      setBw((PrbBw[120]*nprbs)/1e3);    
    
      setPeriodList( P);
      
  }
  
    const onSubmit = (e) =>{
        e.preventDefault();
        onSetSystemConfigs({"nprbs":nprbs,"bw":bw,"scs":numerologies[numerologie],"numerologie":numerologie,"pdschTable":pdschTable,"mcs":mcs,"nLayersDl":nLayersDl,"nLayersUl":nLayersUl,"periodList":periodList})
    }

    return (
    <Form>
        <Form.Group as={Form.Row} controlId="formHorizontalEmail1">
          <Form.Label column >
            Number of Physical Resource Blocks
          </Form.Label>
          <Col sm={20}>
          <Form.Control type="Number" value={nprbs} onChange={(e) => setNprbs(Number(e.target.value))} />
          </Col>
        </Form.Group>

        <fieldset>
        <Form.Group as={Form.Row}>
        <Form.Label as="legend" column >
        Subcarrier Spacing
        </Form.Label>
        <Col sm={20}>
            <Form.Check
          type="radio"
          label="15 kHz"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          onClick={() => setNumerologieAndPeriodN0()}
        />
        <Form.Check
          type="radio"
          label="30 kHz"
          name="formHorizontalRadios"
          id="formHorizontalRadios2"
          onClick={() => setNumerologieAndPeriodN1()}

        />
        <Form.Check
          type="radio"
          label="60 kHz"
          name="formHorizontalRadios"
          id="formHorizontalRadios3"
          onClick={() => setNumerologieAndPeriodN2()}

        />
        <Form.Check
          type="radio"
          label="120 kHz"
          name="formHorizontalRadios"
          id="formHorizontalRadios4"
          onClick={() => setNumerologieAndPeriodN3()}
        />
      </Col>
        </Form.Group>
    </fieldset>

  <Form.Group as={Form.Row} controlId="formHorizontalPassword1">
    <Form.Label column >
    Modulation and Coding Schem
    </Form.Label>
    <Col sm={20}>
      <Form.Control type="Number" value={mcs} onChange={(e) => setMcs(Number(e.target.value))} />
    </Col>
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalPassword2">
    <Form.Label column >
    Number Downlink MIMO Layers
    </Form.Label>
    <Col sm={20}>
      <Form.Control type="Number" value={nLayersDl} onChange={(e) => setNLayersDl(Number(e.target.value))}/>
    </Col>
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalPassword3">
    <Form.Label column >
    Number Uplink MIMO Layers
    </Form.Label>
    <Col sm={20}>
      <Form.Control type="Number" value={nLayersUl} onChange={(e) => setNLayersUl(Number(e.target.value))}/>
    </Col>
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalEmail">
    <Form.Label column >
    NR 3GPP PDSCH TABLE 
    </Form.Label>
    <Col sm={20}>

    <DropdownButton
    as={ButtonGroup}
    id={`dropdown-variants`}
    title="PDSCH TABLE"
    >
        {Object.keys(PDSCH_TABLE).map((key)=>(
        <Dropdown.Item key={key} eventKey={key} onClick={() => setPdschTable(PDSCH_TABLE[key])} value={key} >{key} </Dropdown.Item>
          ),
          )}
      </DropdownButton> 

      <Form.Label>{pdschTable}</Form.Label> 
          </Col>
    </Form.Group>

  <Form.Group as={Form.Row} controlId="submit">
    <Col sm={{ span: 20, offset: 10 }}>
      <Button type="submit" onClick={onSubmit}>Submit</Button>
    </Col>
  </Form.Group>
</Form>
    )
}
export default SystemInputForm;