import React, { useEffect,useState } from "react";
import {UeColors} from "../constants/MapConstants";
import { Popup,Circle } from 'react-leaflet';
import {Button} from "react-bootstrap";

import "../sidebar/SideStyles.scss";


const Ues = ({ueData,selectedPci}) =>{

    const [data,setData] = useState([]);
    const [file,setFile] = useState({});

    const downloadFile = async (e) => {
        e.preventDefault();
      
        const fileName = "Ues";
        const blob = new Blob([file],{type:'application/json'});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };



    useEffect(() =>{
    if (ueData !== undefined && ueData.length > 0){
        setData(ueData);
        setFile(JSON.stringify(ueData));

         }
    },[ueData]);



    return <>
        {data.map((value,id) =>(
        <Circle key={id} center={value.position} pathOptions={{ fillColor: selectedPci === value.pci ? "#ea0a8e" : "#ea0a8e",color:selectedPci === value.pci ? "#ea0a8e" :  UeColors[4],fillOpacity:0.9 }} radius={10}    stroke={true}     >
            <Popup > 
                <span>
                    <ul>
                        <li>id: {id}</li> 
                        <li>SIR [dB]: {Math.round(value.sir*100)/100}</li> 
                        <li>distance [m]: {Math.round(value.distance*100)/100}</li> 
                        <li>bearing[deg]: {Math.round(value.bearing*100)/100}</li> 
                        <li>Serving Cell ID: {value.gnbId}</li> 
                        <li>MCS [idx]: {Math.round(value.mcs)}</li> 
                        <li>N Spatial Layers [N]: 2</li> 
                        <li>DL TP[Mbps]: {value.dlTp}</li>
                        <li>Users per cell {data.filter(val=> val.servingCellId === value.servingCellId).length}</li>
                        <li>Users per sector {data.filter(val=> val.pci === value.pci).length}</li>
                        <li>Serving Cell PCI: {value.pci}</li>

                    </ul>
                </span>          
            </Popup>
        </Circle>        
        ),
        )
        }
    {data.length > 0 &&
    <Button className="DownloadButton" variant="dark"  onClick={downloadFile}>Download as JSON</Button>
    }
        </>
}
export default Ues;

