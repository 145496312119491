  export const qciPerUser = [
        {
          "dayTime": "6-8",
          "Best Effort": 159,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 10,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 10,
          "URLLCColor": "hsl(167, 70%, 50%)"
    
        },
        {
          "dayTime": "8-10",
          "Best Effort": 191,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 6,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 4,
          "URLLCColor": "hsl(167, 70%, 50%)"
    
        },
        {
          "dayTime": "10-12",
          "Best Effort": 130,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 12,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 8,
          "URLLCColor": "hsl(167, 70%, 50%)"
    
        },
        {
          "dayTime": "12-14",
          "Best Effort": 1000,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 38,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 7,
          "URLLCColor": "hsl(167, 70%, 50%)"
    
        },
        {
          "dayTime": "14-16",
          "Best Effort": 99,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 5,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 16,
          "URLLCColor": "hsl(167, 70%, 50%)"
    
        },
        {
          "dayTime": "16-18",
          "Best Effort": 208,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 2,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 1,
          "URLLCColor": "hsl(167, 70%, 50%)"
        },
        {
          "dayTime": "18-20",
          "Best Effort": 580,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 4,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 16,
          "URLLCColor": "hsl(167, 70%, 50%)"
        },
        {
          "dayTime": "20-22",
          "Best Effort": 78,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 1,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 1,
          "URLLCColor": "hsl(167, 70%, 50%)"
        },
        {
          "dayTime": "22-24",
          "Best Effort": 60,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 2,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 3,
          "URLLCColor": "hsl(167, 70%, 50%)"
        },
        {
          "dayTime": "24-6",
          "Best Effort": 48,
          "Best EffortColor": "hsl(149, 70%, 50%)",
          "GBR": 5,
          "GBRColor": "hsl(262, 70%, 50%)",
          "URLLC": 2,
          "URLLCColor": "hsl(167, 70%, 50%)"
        }
      ];