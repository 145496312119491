import React, { useState, useEffect } from "react";
import { ResponsiveLine } from '@nivo/line';
import { Defs } from '@nivo/core';
import { Card,Col} from "react-bootstrap";
import { area,curveMonotoneX } from "d3-shape";

const lineGraphSettings = {
    theme: {
        tooltip:{
            container:{
                background:"black",
            }
        },
        fontSize: '15px',
        textColor: "white",
        axis: {
          ticks: {
            line: {
              stroke: "black"
            },
            text: {
              fill: "white",
            }
          }
        },
        grid: {
          line: {
            stroke: "black",
            strokeWidth: 1,
          }
        }
      },
    };

    const AreaLayer = ({ series, xScale, yScale }) => {
        const areaGenerator = area()
            .x(d => xScale(d.data.x))
            .y0(d => yScale(d.data.y- (3.9680746447595294 + [-0.00039841] * d.data.x)))
            .y1(d => yScale(d.data.y+ (3.9680746447595294 + [-0.00039841] * d.data.x)))
            .curve(curveMonotoneX)
    
        return (
            <>
                <Defs
                    defs={[
                        {
                            id: 'pattern',
                            type: 'patternLines',
                            background: 'transparent',
                            color: 'rgb(31, 119, 180)',
                            lineWidth: 1,
                            spacing: 6,
                            rotation: -45,
                        },
                    ]}
                />
                <path
                    d={areaGenerator(series[0].data)}
                    fill="url(#pattern)"
                    fillOpacity={0.6}
                    stroke='rgb(31, 119, 180)'
                    strokeWidth={2}
                />
            </>
        )
    }

    const AreaLayer2 = ({ series, xScale, yScale }) => {
        const areaGenerator = area()
            .x(d => xScale(d.data.x))
            .y0(d => yScale(14.472806246050634 + [0.17388741] *d.data.x - (2.7112894081282395 + [-0.03010484] * d.data.x)))
            .y1(d => yScale(14.472806246050634 + [0.17388741] *d.data.x+ (2.7112894081282395 + [-0.03010484] * d.data.x)))
            .curve(curveMonotoneX)
    
        return (
            <>
                <Defs
                    defs={[
                        {
                            id: 'pattern',
                            type: 'patternLines',
                            background: 'transparent',
                            color: '#FF851B',
                            lineWidth: 1,
                            spacing: 6,
                            rotation: -45,
                        },
                    ]}
                />
                <path
                    d={areaGenerator(series[0].data)}
                    fill="url(#pattern)"
                    fillOpacity={0.6}
                    stroke="#FF851B"
                    strokeWidth={2}
                />
            </>
        )
    }

const LinePlot = ({x,y,metricName,xlabel,ylabel,height,width} ) => {

    const [data,setData]  = useState([]);
    const [data2,setData2]  = useState([]);



    useEffect(() =>{
        let newData = [];
        let newDataTest = [];

        for (let i=0;i<y.length;i+=1){
            newData.push({"x": x[i],"y":y[i]});
            newDataTest.push({"x": x[i],"y":14.472806246050634 + [0.17388741] *x[i]})
            }
        setData(newData);
        setData2(newDataTest); 
    },[x,y]);

    return (
        <Col md={12} lg={4} >
        < Card style={{ height:height ,width:width}}>
            <Card.Header style={{fontWeight: "bolder", backgroundColor: "#ea0a8e"}} >  {metricName} </Card.Header>
        <Card.Body>
        <ResponsiveLine
        colors={{ scheme: 'category10' }}
        borderColor={{ from: 'color' }}
        curve="monotoneX"
        data={ [{"id": "2-3 Spatial Layers","data":data},{"id": "3-4 Spatial Layers","data":data2},]}
            margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
            xScale={{ type: 'linear' ,min:Math.min(...x)-1,max:Math.max(...x)+1}}
            yScale={{ type: 'linear',min:0,max:Math.max(...y)+8}}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: xlabel,
                legendOffset: 36,
                legendPosition: 'middle',

            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 1,
                tickRotation: 0,
                legend: ylabel,
                legendOffset: -50,
                legendPosition: 'middle',

            }}

            theme={lineGraphSettings.theme}
            pointSize={2}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={false}
            animate={false}
            layers={[
                'grid',
                'markers',
                'areas',
                AreaLayer,
                AreaLayer2,
                'lines',
                'slices',
                'axes',
                'points',
                'legends',
            ]}
            legends={[
                {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemsSpacing: 90,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: "#ea0a8e",
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'black',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}

        />
        </Card.Body>
        </Card>
        </Col>)
}
export default  LinePlot;



