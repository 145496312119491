import { ResponsiveBar } from '@nivo/bar';
import React from "react";
import { Card,Col} from "react-bootstrap";
import "../sidebar/SideStyles.scss";

const lineGraphSettings = {
    theme: {
        tooltip:{
            container:{
                background:"black",
            }
        },
        fontSize: '10px',
        textColor: "white",
        axis: {
          ticks: {
            line: {
              stroke: "black"
            },
            text: {
              fill: "white",
            }
          }
        },
        grid: {
          line: {
            stroke: "black",
            strokeWidth: 1,
          }
        }
      },
    };

const MyResponsiveBar = ({data, metricName,keys,indexBy,schem,selectTimeOfDay }) => {
    const setNumUsers = (e) =>{
        selectTimeOfDay(e.data);
    }
    return (
        <Col md={12} lg={4} >
        < Card className="userBarPlot" >
            <Card.Header style={{fontWeight: "bolder", backgroundColor: "#ea0a8e"}} >  {metricName} </Card.Header>
        <Card.Body>

    <ResponsiveBar

        onClick={(e) => setNumUsers(e)}
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 20, right: 90, bottom: 45, left:50 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        valueFormat={{ format: '', enabled: false }}
        colors={{ scheme: schem }}
        theme={lineGraphSettings.theme}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'dayTime',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Tot. Num. Active Users',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
            </Card.Body>
        </Card>
        </Col>)
};
export default MyResponsiveBar;