import React from "react";
import {Modal} from "react-bootstrap";
import InputForm from "./InputForm";

function MyVerticallyCenteredModal(props) {

    return (
      <Modal
        show={props.show}
        onHide={props.onHide}        
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          TDD Slots Configurations
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <InputForm onSetTddConfigs={props.passedFunction} systemSet={props.systemSet}></InputForm>
        </Modal.Body>
      </Modal>
    );
  }
export default MyVerticallyCenteredModal;
  