import {P,PDSCH_TABLE} from "../common/helpers";

export const view = "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png";
export const viewBlackAndWhite = "https://tile.openstreetmap.org/{z}/{x}/{y}.png";  
export const viewToner = "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png";

export const listGnbs =[{id:"LY3828",position:[51.32182222222222,12.341144444444442],pci:[232,233,231],azimuthAntenna:[60,180,300]},{id:"LY1093",position:[51.323011111111114,12.333416666666665],pci:[354,355,356],azimuthAntenna:[60,180,300]},{id:"LY0254",position:[51.327725000000015,12.323197222222225],pci:[18,19,20],azimuthAntenna:[60,180,300]},{id:"LY1044",position:[51.33376944444444,12.33614166666667],pci:[329,328,327],azimuthAntenna:[60,180,300]},{id:"LY2228",position:[51.32748611111112,12.336052777777779],pci:[492,494,493],azimuthAntenna:[60,180,300]}];
export const mapCenter = [51.325375263211534, 12.332082591200535];
export const ueCenter = {lat: 51.12,lng: 12.33};
export const tddConfigs = {"numerology":1,"period":2.5,"totalNumberOfSlotsPerP":5,"dlSlots":3,"ulSlots":1,"dlSymbols":10,"ulSymbols":2};
export const systemConfig = {"f":3.6e9,"scs":30,"mcs":27,"nLayersDl":1,"nLayersUl":1,"nprbs":245,"numerologie":1,"pdschTable":PDSCH_TABLE["NR_PDSCH_TABLE_2"],"bw":90,"periodList":P.filter(item => item !== 0.625 && item !== 1.25 )};
export const darkView = "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png";
export const UeColors = ["#FFDC00","#0074D9","#85144b","#01FF70","#39CCCC","#3D9970","#F012BE"];


export const usersPerPci = [{pci:231,avgUsers:102},{pci:232,avgUsers:90},{pci:233,avgUsers:60},{pci:354,avgUsers:120},{pci:355,avgUsers:80},{pci:356,avgUsers:100},{pci:18,avgUsers:60},{pci:19,avgUsers:40},{pci:20,avgUsers:80},{pci:327,avgUsers:90},{pci:328,avgUsers:56},{pci:329,avgUsers:78},{pci:492,avgUsers:55},{pci:493,avgUsers:111},{pci:494,avgUsers:85}];