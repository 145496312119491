import React, { useState,useEffect } from "react";
import {Button,Table} from "react-bootstrap";
import {nrTp} from "../common/helpers";
import MyVerticallyCenteredModal from "./ModalTddConfigs"
import MyVerticallyCenteredModalS from "./ModalSystemConfigs"
import ResourceTable from "../subframestable/ResourceTable";
import "./LeftBar.scss";


const sysMap = {"scs":"SCS [KHz]","bw":"System BW [MHz]","pdschTable":"PDSCH TABLE","nLayersDl":"Max DL Layers [NLayers]","nLayersUl":"Max UL Layers [NLayers]"};
const tddMap =  {"numerology":"SCS Config","period":"Period Length [ms]","totalNumberOfSlotsPerP":"Slots Per Period [N]","dlSlots":"DL Slots [N]","ulSlots":"UL Slots [N]","dlSymbols":"DL Symbols [N]","ulSymbols":"UL Symbols [N]"};

function LeftBar ({tddConfig}){

    const [tddConfigs,setTddConfigs] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [systemConfigs,setSystemConfigs] = useState({});
    const [modalSShow, setModalSShow] = useState(false);
    const [showTddConfig, setShowTddConfig] = useState(false);
    const [tp,setTp] = useState({"Downlink TP [Mbps]":0,"Uplink TP [Mbps]":0});

    useEffect(() =>{
        if (tddConfig.ttd !==undefined){
        setTddConfigs(tddConfig.ttd);
        setSystemConfigs(tddConfig.sys);

        }
    },[tddConfig])


    const setSystemConfig  = (configs) =>{
        setSystemConfigs(configs);
    }

    useEffect(() => {
        if (Object.keys(systemConfigs).length !==0 && Object.keys(tddConfigs).length !==0){
            let nrDlTp =  nrTp(tddConfigs.numerology,systemConfigs.mcs,systemConfigs.nprbs,systemConfigs.nLayersDl,systemConfigs.pdschTable) *(tddConfigs.dlSlots/tddConfigs.totalNumberOfSlotsPerP + ((tddConfigs.totalNumberOfSlotsPerP-tddConfigs.dlSlots-tddConfigs.ulSlots)/tddConfigs.totalNumberOfSlotsPerP)*(tddConfigs.dlSymbols/14) );
            let nrUlTp =  nrTp(tddConfigs.numerology,systemConfigs.mcs,systemConfigs.nprbs,systemConfigs.nLayersUl,systemConfigs.pdschTable) *(tddConfigs.ulSlots/tddConfigs.totalNumberOfSlotsPerP + ((tddConfigs.totalNumberOfSlotsPerP-tddConfigs.dlSlots-tddConfigs.ulSlots)/tddConfigs.totalNumberOfSlotsPerP)*(tddConfigs.ulSymbols/14) );
            setTp({"Downlink TP [Mbps]":Math.round(nrDlTp),"Uplink TP [Mbps]":Math.round(nrUlTp)});
        }
      }, [systemConfigs,tddConfigs])

    return <div className="leftbar">
        <Button variant="dark" onClick={() => setModalSShow(true)} style={{margin:"3px",backgroundColor:"#ea0a8e"}}>
            Set System Configurations
        </Button>
        <Button variant="dark" onClick={() => setModalShow(true)} style={{margin:"3px",backgroundColor:"#ea0a8e"}}>
            Set TDD Configurations
        </Button>

        <p><b>gNB Configurations</b> </p>

        <Table striped bordered hover size="sm">
        <tbody >
        {Object.keys(sysMap).map((key)=>(
            <tr key={key} >
                <td key={`td${key}`} >{sysMap[key]}</td>
                <td key={`n${key}`}>{systemConfigs[key]}</td>
            </tr>
            ),
            )}
        </tbody>
        </Table>

        <b><p>TDD Configurations</p></b>  

        <Table striped bordered hover size="sm">
        <tbody >
        {Object.keys(tddMap).map((key)=>(
            <tr key={key} >
                <td key={`td${key}`}>{tddMap[key]}</td>
                <td key={`n${key}`}>{tddConfigs[key]}</td>
            </tr>
            ),
            )}
        </tbody>
        </Table>

        <p><b>Max. Possible Throughput</b></p>

        <Table striped bordered hover size="sm">
        <tbody >
        {Object.keys(tp).map((key)=>(
             <tr key={key} >
        <td key={`td${key}`}>{key}</td>  
        <td key={`n${key}`} >{tp[key]}</td>
        </tr>
        ),
        )}
        </tbody>
        </Table>

        <Button variant="dark" onClick={() => setShowTddConfig(!showTddConfig)} style={{margin:"5px",backgroundColor:"#ea0a8e"}} >
            Show TDD Configurations
        </Button>
        <ResourceTable tddConf={tddConfigs} show={showTddConfig}/>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          passedFunction={setTddConfigs}
          systemSet={{"P":systemConfigs["periodList"],"numerologie":systemConfigs["numerologie"]}}
        />
        <MyVerticallyCenteredModalS
          show={modalSShow}
          onHide={() => setModalSShow(false)}
          passedFunction={setSystemConfig}
        /> 
        <p className="Footer" >Developed by <a href="https://github.com/MBCharaf">MBCharaf</a> @ HTWK </p>

        </div>

    }
export default LeftBar;