import React from "react";
import { LayersControl,TileLayer } from 'react-leaflet';
import {view,viewBlackAndWhite,viewToner} from "../../constants/MapConstants";

function MapTileLayerControl() {

  return (
      <LayersControl position="topright">
      <LayersControl.BaseLayer checked name="OpenStreetMap.BlackAndWhite">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={viewBlackAndWhite}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={view}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OpenStreetMap.Dark">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={viewToner}
        />
      </LayersControl.BaseLayer>
      </LayersControl>
      
    )
}
export default MapTileLayerControl;