
import React,{useState} from "react";
import { Table,Col } from "react-bootstrap";
import "./styles.scss";

const slotsColorsMap = {"D":"table-success","F":"table-light","U":"table-warning"}

function ResourceTable ({tddConf,show}){
    const [ofdmSymbolsTable,setOfdmSymbols] = useState([]);

    const onSlotClick = (typeOfSlots) =>{
        if (typeOfSlots === "D" || typeOfSlots === "U") {
            let ofdmSymbols = [];
            for (let i =0;i<14;i++){
                ofdmSymbols.push(<td  key={i} className={slotsColorsMap[typeOfSlots]}>OFDM Symbol {i} ({typeOfSlots})</td> )
        }
        setOfdmSymbols (ofdmSymbols);
    }else {
        let ofdmSymbols = [];
        for (let i =0;i<tddConf.dlSymbols;i++){
            ofdmSymbols.push(<td  key={`D ${i}`} className={slotsColorsMap["D"]}>OFDM Symbol {i} (D)</td> )
        }
        for (let i =0;i<14 - (tddConf.dlSymbols+tddConf.ulSymbols);i++){
            ofdmSymbols.push(<td key={`F ${i}`} className={slotsColorsMap["F"]}>OFDM Symbol {i} (F)</td> )
        }
        for (let i =0;i<tddConf.ulSymbols;i++){
            ofdmSymbols.push(<td key={`U ${i}`} className={slotsColorsMap["U"]}>OFDM Symbol {i} (U)</td> )
        }
        setOfdmSymbols (ofdmSymbols);

    }
}
    const setSlots = (numberOfSlots,typeOfSlots) =>{
        let slotsList = [];
        for (let i =0;i<numberOfSlots;i++){
            slotsList.push(<td  key={i} className={slotsColorsMap[typeOfSlots]} onClick={() => onSlotClick(typeOfSlots)} ></td> )
        }
        return    slotsList;   
    };
    const calculateTddSlots = () =>{
        const periodsPerFrame = 10 / tddConf.period;
        let totalSlots = [];
        for (let i = 0;i<periodsPerFrame;i++){
            totalSlots.push(setSlots(tddConf.dlSlots,"D"));
            totalSlots.push(setSlots(tddConf.totalNumberOfSlotsPerP - (tddConf.dlSlots+tddConf.ulSlots),"F"));
            totalSlots.push(setSlots(tddConf.ulSlots,"U"));
        }
        return totalSlots;
    }
    const uDF = () =>{
        return <Table >
        <tbody>
            <tr >
            <td  className={slotsColorsMap["D"]}>D</td>
            <td   className={slotsColorsMap["U"]}>U</td>
            <td  className={slotsColorsMap["F"]}>F</td>
            </tr>
        </tbody>
        </Table>
    }

    return (
        <>
        {show &&
      <div className="FloatingDownWindow">
        <Col xs={1}>
        {uDF()}
        </Col>
        <h3>5G Radio Frame [10 ms] </h3>
        <Table striped bordered hover size="sm"  >
        <tbody>
            <tr style={{ height:'8vh',width:"8vw"}}>
            { [0,1,2,3,4,5,6,7,8,9].map(value =>(    
            <td key={`U ${value}`} className="table-info" >Subframe{value}</td>
            ),)
            }
            </tr>
        </tbody>
    </Table>
    
    <Table striped bordered hover size="sm">
        <tbody >
            <tr style={{ height:'8vh',width:"8vw"}}>
            {calculateTddSlots()}
            </tr>
        </tbody>

    </Table>

    <h3>OFDM Symbols in a Slot (Slot len [{tddConf.period/tddConf.totalNumberOfSlotsPerP} ms])</h3>

      <Table striped bordered hover size="sm"  >
          <tbody>
          <tr style={{ height:'8vh',width:"8vw"}} >
          {ofdmSymbolsTable}
      </tr>
          </tbody>
    </Table>

    </div>
        }
        </>
    )
        
}
export default ResourceTable;
