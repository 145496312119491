import React ,{useState}from "react";
import { MapContainer, FeatureGroup} from 'react-leaflet';
// import DraggableMarker from "../ues/DragableUe";
import {mapCenter} from "../constants/MapConstants";
import MapTileLayerControl from "./mapTileLayer/MapTileLayers";
import { EditControl } from "react-leaflet-draw";
import Ues from"../ues/Ues";
import SideWindow from "../sidebar/SideWindow";
import BSs from "../bss/BSs";
import "./MapStyle.scss";
import "leaflet-draw/dist/leaflet.draw.css";



function MapView ({onSetTddConfigs}){

  const [boarder,setBoarder] = useState([]);
  const [gnbImported,setGnbImported] = useState(false);
  const [selectedPci,setSelectedPci] = useState(0);
  const [simulationResult,setSimulationResult] = useState([]);


  const setUeSimulationResult = (simulationResults) =>{
    setSimulationResult(simulationResults);
  }

  const setGnBs = (show) =>{
    setGnbImported(show);

  }
  const _onCreated = e =>{
    if (e.layerType === "rectangle"){
      setBoarder([e.layer._latlngs[0][0],e.layer._latlngs[0][2]]);
    }
  }
  const _onEdited = e =>{
    console.log(e);
  }
  const _onDeleted = e =>{
    console.log(e);
  }
  const onsetSelectedPci  = (pci) =>{
    setSelectedPci(pci);
}


    return (<>
        <MapContainer  className="map" center={mapCenter} zoom={15} scrollWheelZoom={true}>
          <FeatureGroup>
            <EditControl position="topleft" onCreated={_onCreated} onEdited={_onEdited} onDeleted={_onDeleted}   

            draw={{
              rectangle:{
                shapeOptions: { color: "#ea0a8e",fill:false },
                showLength: true,
                showArea: true

              },
              polyline:false,
              circle:false,
              polygon:{
                shapeOptions: { color: "#ea0a8e" ,clickable:true},
                showLength: true,
                showArea: true,

              },
              circlemarker:false,
              marker:true

            }}>

            </EditControl>
            </FeatureGroup>
            <MapTileLayerControl/>

        <Ues ueData={simulationResult} selectedPci={selectedPci} ></Ues>
        <BSs onSetTddConfigs={onSetTddConfigs} showGnbs={gnbImported} onSelectedPci={onsetSelectedPci}></BSs> 

        {/* <DraggableMarker/> */}
        </MapContainer>
        <SideWindow area={boarder}  onImportGnbsTrue={setGnBs} onSimulateUes={setUeSimulationResult} selectedPci={selectedPci}></SideWindow>
</>

    )
};
export default MapView;