import React, { useState } from "react";
import MapView from "../components/map/MapView";
import LeftBar from "../components/leftbar/leftbar";

function MainPage (){
    const [tddConfigs,setTddConfigs] = useState({});



    const setTddConfig  = (configs) =>{
        setTddConfigs(configs);
    }

    

    return <>
        <LeftBar tddConfig={tddConfigs}></LeftBar>
        <MapView onSetTddConfigs={setTddConfig} ></MapView>
        </>

    ;


};

export default MainPage
