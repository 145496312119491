import React, {useState,useEffect}from "react";
import ScatterPlot from "../plots/ScatterPlot";
import {Row,Button,OverlayTrigger,Popover,Form,Col,DropdownButton,Dropdown,ButtonGroup} from "react-bootstrap";
import {ecdf} from "@agile-radio-lab/radiolabjs/base/dist/stats";
import {dropUesRandom,calcSirDistanceBearingToCell,nrTp,sinrToMcsMean} from "../common/helpers";
import {  NR_PDSCH_TABLE_2 } from "@agile-radio-lab/radiolabjs/base/dist/consts/nr-mcs-tables";
import LinePlot from "../plots/LinePlot";
import BarPlot from "../plots/BarPlot";
import {usersPerPci} from "../constants/MapConstants";
import "./SideStyles.scss";
import {qciPerUser} from "./UserData";
import {DatePickerForm} from "./DatePicker";



const SideWindow = ({area,onImportGnbsTrue,onSimulateUes,selectedPci}) =>{

    const [plotData,setPlotData]  = useState({x:[],y:[]});
    const [plotDataSelected,setPlotDataSelected]  = useState({x:[],y:[]});

    const [plotDataTp,setPlotDataTp]  = useState({x:[],y:[]});
    const [plotDataTpSelected,setPlotDataTpSelected]  = useState({x:[],y:[]});

    const [hide,setHide]  = useState(false);
    const [showPlots,setShowPlots] = useState(false);
    const [sinrModelArray,setSinrModelArray] = useState({"SINR":[],"MCS":[]});
    const [simData,setSimData] = useState([]);
    const [ratioActiveUsers,setRatioActiveUsers] = useState(10);

    const setRatioActiveUes = (value) =>{
      if (Number(value) <= 0){
        setRatioActiveUsers(1);
      }
      else if (Number(value) > 100){
        setRatioActiveUsers(100);
      }
      else{
        setRatioActiveUsers(Number(value));


      }
  }
    const randomChoiceFromArray = (array,numberOfUes) =>{
      let wantedValues = [];
      for (let i = 0;i<numberOfUes;i++){
        let randomElement = array[Math.floor(Math.random() * array.length)];
        let index = array.indexOf(randomElement);
        wantedValues.push(randomElement);
        array.splice(index, 1);
      }
      return wantedValues;
    }

    const onClickDropUes = (numberofUes,selective=false,ratio=0.1) =>{
        if (area.length !== 0){
            let ues = dropUesRandom(area,numberofUes);
            let ueArr = calcSirDistanceBearingToCell(ues);
            let newUeArr = [];
            if (selective){
            usersPerPci.forEach(value=>{
              let filteredPCI = ueArr.filter(val=> val.pci === value.pci);
              if (filteredPCI.length > 0){ 
                  let targetUsers = randomChoiceFromArray(filteredPCI,Math.round(value.avgUsers*ratio));
                  newUeArr = [...newUeArr,...targetUsers];
              }
            });
            ueArr = newUeArr.filter(val=> val !== undefined);

          }

            let sirArr = [];
            let tpArr  = [];
            ueArr.forEach(value =>{
              sirArr.push(value.sir);
              value['dlTp'] = Math.round(nrTp(1,Math.round(sinrToMcsMean(2,value.sir)) > 27? 27 :Math.round(sinrToMcsMean(2,value.sir)),273,2,NR_PDSCH_TABLE_2)/ueArr.filter(val=> val.pci === value.pci).length);
              tpArr.push(value['dlTp']);
            })

            onSimulateUes(ueArr);
            setSimData(ueArr);

            const res = ecdf(sirArr, false);
            setPlotData({x:res.x,y:res.y});

            const resTp = ecdf(tpArr, false);
            setPlotDataTp({x:resTp.x,y:resTp.y});
        }else{
            return 0
        }

    }

    const onClickImportgNBs = () =>{
        onImportGnbsTrue(true);

    };
    const onSelectTimeOfDay = (data) => {
      onClickDropUes(Number(data["Best Effort"])+Number(data["GBR"])+Number(data["URLLC"]))
    }
    const popover = (
        <Popover id="popover-basic">
          <Popover.Content>
              Not Implemented yet !!
          </Popover.Content>
        </Popover>
      );

      const ImportButton = () => (
        <OverlayTrigger trigger="click" placement="left" overlay={popover}>
          <Button variant="dark" className="SideButton" onClick={onClickImportgNBs} >Import gNBs </Button>
        </OverlayTrigger>
      );

      const ImportUe = () => (
        <OverlayTrigger trigger="click" placement="left" overlay={popover}>
          <Button variant="dark" className="SideButton" >Import UEs </Button>
        </OverlayTrigger>
      );


      const PredicteButton = () => (
        <OverlayTrigger trigger="click" placement="left" overlay={popover}>
          <Button variant="dark" className="SideButton" onClick={() =>setShowPlots(true)} >Predict KPIs </Button>
        </OverlayTrigger>
      );

      useEffect(() =>{
        let sinrRange = [];
        let McsRange = [];
        for (let i =-5;i<=30;i++){
          sinrRange.push(i);
          McsRange.push(sinrToMcsMean(2,i))
        }
        setSinrModelArray({"SINR":sinrRange,"MCS":McsRange});  

            
    },[]);

    useEffect(() =>{
      if (simData.length > 0  && selectedPci !==0){
        let filteredData = simData.filter(val=> val.pci === selectedPci);

        let sirArr = [];
        let tpArr  = [];
        filteredData.forEach(value =>{
          sirArr.push(value.sir);
          tpArr.push(value.dlTp);

        })
        const res = ecdf(sirArr, false);
        setPlotDataSelected({x:res.x,y:res.y});
        const resTp = ecdf(tpArr, false);
        setPlotDataTpSelected({x:resTp.x,y:resTp.y});
      }

  },[selectedPci,simData]);

  const onSubmit = ()=>{
    onClickDropUes(15000,true,ratioActiveUsers/100);

  } 

  return (
        <>
        <Button variant="dark" className="MinimizButton" onClick={() =>setHide(!hide)} style={{backgroundColor:"#ea0a8e" }} >{hide?<p>-</p>:<p>+</p>}</Button>
        {hide &&
        <div className="FloatingSideWindow"  >
        <ImportButton className="SideButton"/>
        <ImportUe  className="SideButton"/>
        <Button  variant="dark" className="SideButton" onClick={()=>onClickDropUes(300)} >Drop UEs Rand. </Button>
        <PredicteButton  className="SideButton"></PredicteButton>

        <p><b>Select date and time slot</b></p>
        <Form>
        <Form.Group as={Form.Row} controlId="formHorizontalEmail1">
          <Col sm={20}>
            <DatePickerForm/>
          </Col>
          <Col sm={20}>
            <DropdownButton as={ButtonGroup}
              id={`dropdown-variants`}
              title="Time"
              style={{backgroundColor:"#ea0a8e" }}
              variant="#ea0a8e"
              >
              <Dropdown.Item >1:00 pm</Dropdown.Item>
            </DropdownButton> 
          </Col>
          </Form.Group>
          </Form>

          <p><b>Select percentage of parallel active users</b></p>

          <Form>

          <Form.Group as={Form.Row} controlId="formHorizontalEmail1">

          <Col sm={20}>
          <Form.Control type="Number" placeholder="% of active users "  value={ratioActiveUsers} onChange={(e) => setRatioActiveUes(e.target.value)} />
          </Col>
          <Col sm={20}>
            <Button variant="#ea0a8e" style={{backgroundColor:"#ea0a8e" }} onClick={onSubmit} >Drop Users</Button>
          </Col>
        </Form.Group>
        </Form>

        <p><b>KPI Plots</b></p>     

        <Row>
        <ScatterPlot x={plotData.x} y={plotData.y} x1={plotDataSelected.x} y1={plotDataSelected.y}  metricName="SIR [dB]"  metricName1={`Selected PCI ${selectedPci}`} xlabel="UEs SIR [dB]" ylabel="CDF" height="30vh" width="23vw" xlim={{min:-5,max:30}} ></ScatterPlot>
        </Row>
        {showPlots &&
        <>
        <Row>
        <ScatterPlot x={plotDataTp.x} y={plotDataTp.y} x1={plotDataTpSelected.x} y1={plotDataTpSelected.y} metricName="DL TP [Mbps]" metricName1={`Selected PCI ${selectedPci}`} xlabel="UEs TP [Mbps]" ylabel="CDF" height="30vh" width="23vw" xlim={{min:0,max:plotDataTp.x.length> 0?Math.max(...plotDataTp.x):100}} ></ScatterPlot>
        </Row>
        <Row>
        <LinePlot x={sinrModelArray["SINR"]} y={sinrModelArray["MCS"]} metricName="DL MCS Model"  xlabel="SINR [dB]" ylabel="MCS [idx]" height="30vh" width="23vw"></LinePlot>
        </Row>
        </>
        }
        </div>
        }

        <div className="barPlotDiv" >
        <BarPlot  data={qciPerUser} metricName="Number of Active UEs Per Day"  height="1rem" width="30rem" keys={["Best Effort","GBR","URLLC"]} indexBy={"dayTime"} schem={"nivo"} selectTimeOfDay={onSelectTimeOfDay} ></BarPlot>
        </div>
        </>
    )

}
export default SideWindow;
