import React,{useEffect, useState} from "react";
import {Form,Col,Button,DropdownButton,Dropdown,ButtonGroup} from "react-bootstrap";
import {totalNumberOfSlotsPerPeriod,OfdmSymbolsPerSlot} from "../common/helpers";
import MathJax from 'react-mathjax-preview';
import {P} from "../common/helpers"

const pEquation = '`S = P.2^mu`';

const math = String.raw`${pEquation}`;

function InputForm ({onSetTddConfigs,systemSet}){
    const [numerologie, setNumerologie] = useState(0);
    const [period, setPeriod] = useState(10);
    const [dlSlots, setDlSlots] = useState(5);
    const [ulSlots, setUlSlots] = useState(5);
    const [dlSymbols, setDlSymbols] = useState(7);
    const [ulSymbols, setUlSymbols] = useState(2);
    const [periodList,setPeriodList] = useState(P);
    
    useEffect(()=>{
      if(systemSet.P !== undefined){
        setNumerologie(systemSet.numerologie);
        setPeriodList(systemSet.P);
        
      }else{
        setPeriodList(P);
        setNumerologie(0);
      }

    },[systemSet])

    const onSubmit = (e) =>{
        e.preventDefault();
        onSetTddConfigs({"numerology":numerologie,"period":period,"totalNumberOfSlotsPerP":totalNumberOfSlotsPerPeriod(period,numerologie),"dlSlots":Number(dlSlots),"ulSlots":Number(ulSlots),"dlSymbols":Number(dlSymbols),"ulSymbols":Number(ulSymbols)})
    }
    const setNumberOfDlSlots = (value) =>{
        if (Number(value) <= totalNumberOfSlotsPerPeriod(period,numerologie)){
            setDlSlots(value);
        }
        else{
            setDlSlots(totalNumberOfSlotsPerPeriod(period,numerologie));
        }
    }
    const setNumberOfUlSlots = (value) =>{
        if (Number(value) <=  (totalNumberOfSlotsPerPeriod(period,numerologie) - dlSlots )){
            setUlSlots(value)
        }
        else{
            setUlSlots((totalNumberOfSlotsPerPeriod(period,numerologie) - dlSlots ));
        }
    }
    const setNumberOfDlSymbols = (value) =>{
        if (Number(value) < OfdmSymbolsPerSlot){
            setDlSymbols(value);
        }
        else{
            setDlSymbols(OfdmSymbolsPerSlot);
        }
    }
    const setNumberOfUlSymbols = (value) =>{
        if (Number(value) <= (OfdmSymbolsPerSlot - dlSymbols)){
            setUlSymbols(value)
        }
        else{
            setUlSymbols(OfdmSymbolsPerSlot - dlSymbols);
        }
    }
    return (
    <Form>

  <Form.Group as={Form.Row} controlId="formHorizontalEmail1">
    <Form.Label column >
        Uplink Downlink Periodacity [ms] 
    </Form.Label>
    <Col sm={20}>
    <DropdownButton
    as={ButtonGroup}
        id={`dropdown-variants`}
        title="Period"
      >
        {periodList.map((value) =>(
        <Dropdown.Item key={value} eventKey={value} onClick={() => setPeriod(value)}>{value} ms</Dropdown.Item>
          ),
          )}
      </DropdownButton> 
      <Form.Label>{period} ms</Form.Label> 
          </Col>
    </Form.Group>


  <Form.Group as={Form.Row} controlId="formHorizontalEmail2">
    <Form.Label column >
    Total Number of Slots in one Period 
    </Form.Label>
    <MathJax math={math} />

    <Col sm={30}>
    <Form.Label column >
        {totalNumberOfSlotsPerPeriod(period,numerologie)} slots
    </Form.Label>
        </Col>
        
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalEmail3">
    <Form.Label column >
    Slots per Subframe
    </Form.Label>
    <Col sm={30}>
    <Form.Label column >
        {1/(period/totalNumberOfSlotsPerPeriod(period,numerologie))} slots
    </Form.Label>
        </Col>
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalEmail4">
    <Form.Label column >
      Number of Downlink Slots
    </Form.Label>
    <Col sm={20}>
      <Form.Control type="Number" value={dlSlots} onChange={(e) => setNumberOfDlSlots(e.target.value)} />
    </Col>
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalPassword5">
    <Form.Label column >
    Number of Uplink Slots
    </Form.Label>
    <Col sm={20}>
      <Form.Control type="Number" value={ulSlots} onChange={(e) => setNumberOfUlSlots(e.target.value)} />
    </Col>
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalPassword6">
    <Form.Label column >
    Number of Downlink Symbols
    </Form.Label>
    <Col sm={20}>
      <Form.Control type="Number" value={dlSymbols} onChange={(e) => setNumberOfDlSymbols(e.target.value)}/>
    </Col>
  </Form.Group>

  <Form.Group as={Form.Row} controlId="formHorizontalPassword7">
    <Form.Label column >
    Number of Uplink Symbols
    </Form.Label>
    <Col sm={20}>
      <Form.Control type="Number"  value={ulSymbols} onChange={(e) => setNumberOfUlSymbols(e.target.value)}/>
    </Col>
  </Form.Group>

  <Form.Group as={Form.Row}>
    <Col sm={{ span: 20, offset: 10 }}>
      <Button type="submit" onClick={onSubmit}  >Submit</Button>
    </Col>
  </Form.Group>
</Form>
    )
}
export default InputForm;