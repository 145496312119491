import React from "react";
import {Modal} from "react-bootstrap";
import SystemInputForm from "./SystemInputForm";

function MyVerticallyCenteredModalS(props) {

    return (
      <Modal
        show={props.show}
        onHide={props.onHide}        
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          System Configurations
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <SystemInputForm onSetSystemConfigs={props.passedFunction} ></SystemInputForm>
        </Modal.Body>
      </Modal>
    );
  }
export default MyVerticallyCenteredModalS;
  