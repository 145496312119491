import { NrSchedulingParams } from "@agile-radio-lab/radiolabjs/base/dist/types/NrSchedulingParams";
import { CP_NORMAL } from "@agile-radio-lab/radiolabjs/base/dist/consts/nr-common";
import {calculateNrThroughput} from "@agile-radio-lab/radiolabjs/base/dist/nr-throughput";
import L from 'leaflet';
import {listGnbs} from "../constants/MapConstants";
import {  NR_PDSCH_TABLE_1,NR_PDSCH_TABLE_2,NR_PDSCH_TABLE_3 } from "@agile-radio-lab/radiolabjs/base/dist/consts/nr-mcs-tables";

export const PDSCH_TABLE = {"NR_PDSCH_TABLE_1":NR_PDSCH_TABLE_1,"NR_PDSCH_TABLE_2":NR_PDSCH_TABLE_2,"NR_PDSCH_TABLE_3":NR_PDSCH_TABLE_3};


// Table 16-1.1 Slots Configuration Period when pattern 1 is indicated TS 38.211
export const P = [0.5,0.625,1,1.25,2,2.5,5,10];

// TS 38.213 Slots Configuration
export const totalNumberOfSlotsPerPeriod = (P,u) =>{
    return P * (2**u);
}
// TS 38.211 Table 4.2-1
export const numerologies = {0:15,1:30,2:60,3:120,4:240};

// TS 38.211 Table 4.3.2-1 Number of OFDM symbols per slot, slots per frame, and slots per subframe for normal cyclic prefix. 
export const slotsPerFrame = {0:10,1:20,2:40,3:80,4:160};
export const slotsPerSubFrame = {0:1,1:2,2:4,3:8,4:16};

export const OfdmSymbolsPerSlot = 14;
// BW of a PRB in kHz for each of SCS config
export const PrbBw = {15:180,30:360,60:720,120:1440,240:2880};

export const nrTp = (numerology,mcs,nprbs,nLayers,pdschTable) =>{
    let params = new NrSchedulingParams(numerology, CP_NORMAL, 0, nprbs, pdschTable, mcs, nLayers);
    let res = calculateNrThroughput(params);
    return res.tp.mbps;
}
export const sirDistance = (dMin,dList) => {
   return  10 * Math.log10((dMin**-2)/(dList.map(value =>value**-2)).reduce((a,b)=> a+b));
}
// Drop N number of UEs is a rectangular area randomly  lat: 51.33693815409754 lng: 12.316985430658743 lat: 51.31741529144361 lng: 12.349933792723112
export const dropUesRandom = (dropArea,numberofUes) =>{
    let latLngArray = [];
    const latDiffSetp= Math.abs(dropArea[0].lat - dropArea[1].lat);
    const lngDiffStep = Math.abs(dropArea[0].lng - dropArea[1].lng);

    for (let i = 0; i<=numberofUes;i++){
        let  uePosition = L.latLng((Math.random()*latDiffSetp + Math.min(dropArea[0].lat,dropArea[1].lat)),(Math.random()*lngDiffStep + Math.min(dropArea[0].lng,dropArea[1].lng)));
        latLngArray.push(uePosition);
    }
    return latLngArray;
}

export const calcSirDistanceBearingToCell = (uePs) =>{
    let dataSimulation = [];

    for (let i=0;i<uePs.length;i++){

            let distanceList = [];
            let distances  = [];
            listGnbs.map((gnb)=>{
              distanceList.push({"distanceToGnb":uePs[i].distanceTo(gnb.position),"gnbId":gnb.id,"gnbPosition":gnb.position,"pci":gnb.pci,"azimuth":gnb.azimuthAntenna});
              distances.push(uePs[i].distanceTo(gnb.position));
              return 0;
            });
            let minDistance = Math.min(...distances);
            let interferingGNBs = distances.filter(item => item !== Math.min(...distances));
            let servingGnb = distanceList.filter(item => item.distanceToGnb === minDistance);
            let f1 = servingGnb[0].gnbPosition[0], l1 = servingGnb[0].gnbPosition[1], f2 = uePs[i].lat, l2 = uePs[i].lng;
            let brng = haverSine(f1,l1,f2,l2);

            let brngDiff = servingGnb[0].azimuth.map(value => Math.abs(value - brng));
            let minBearinDiffIndex = brngDiff.indexOf(Math.min(...brngDiff));
            dataSimulation.push({ueId: i,position:uePs[i],sir:sirDistance(minDistance,interferingGNBs),distance:minDistance,bearing:brng,servingCellId:distances.indexOf(minDistance),mcs:sinrToMcsMean(2,sirDistance(minDistance,interferingGNBs)),gnbId:servingGnb[0].gnbId,pci:servingGnb[0].pci[minBearinDiffIndex],azimuthAntenna:servingGnb[0].azimuth[minBearinDiffIndex]})
        }
        return dataSimulation;
    }

export const sinrToMcsMean = (spatialRank,sinr) =>{
    const mcsMean = spatialRank < 3 ? 15.748024609414358 + [0.39035601] * sinr : 14.472806246050634 + [0.17388741] * sinr;
    return mcsMean;
}

    
export const sinrToMcsStd = (spatialRank,sinr) =>{
    const mcsStd = spatialRank < 3 ? 3.9680746447595294 + [-0.00039841] * sinr : 2.7112894081282395 + [-0.03010484] * sinr;
    return mcsStd;
}


// haversine formula
export const haverSine = (f1,l1,f2,l2) =>{
    let toRadian = Math.PI / 180;
    let y = Math.sin((l2-l1)*toRadian) * Math.cos(f2*toRadian);
    let x = Math.cos(f1*toRadian)*Math.sin(f2*toRadian) - Math.sin(f1*toRadian)*Math.cos(f2*toRadian)*Math.cos((l2-l1)*toRadian);
    let brng = Math.atan2(y, x)*(180/Math.PI);
    brng += brng < 0 ?  360:0 ;
    return brng;
}

// inverse haversine formula
export const inverseHaversine = (location, distance, brng) =>{
    let toRadian = Math.PI / 180;
    const R = 6371e3;
    const lat = Math.asin(Math.sin(location[0]*toRadian)*Math.cos(distance/R)+Math.cos(location[0]*toRadian)*Math.sin(distance/R)*Math.cos(brng*toRadian))*(180/Math.PI);
    const lon = location[1] + Math.atan2(Math.sin(brng*toRadian)*Math.sin(distance/R)*Math.cos(location[0]*toRadian),Math.cos(distance/R)-Math.sin(location[0]*toRadian)*Math.sin(lat*toRadian))*(180/Math.PI);
    return [location,[lat,lon]]
}